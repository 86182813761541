import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { UserProvider } from './UserContext';
import { supabase } from './supabaseClient';
import Analytics from './services/analytics';

// Lazy load components
const Dashboard = lazy(() => import('./components/Dashboard'));
const SignIn = lazy(() => import('./components/SignIn'));
const SignUp = lazy(() => import('./components/SignUp'));
const HomePage = lazy(() => import('./components/HomePage'));
const HomePageHeadshots = lazy(() => import('./components/HomePage-Headshots'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const NotFound = lazy(() => import('./components/NotFound'));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions'));
const RefundPolicy = lazy(() => import('./components/RefundPolicy'));

// Create a component to track page views
function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    // Track page view on route change
    Analytics.page({
      path: location.pathname,
      title: document.title
    });
  }, [location]);

  return null;
}

function AuthWrapper() {
  const navigate = useNavigate();

  useEffect(() => {
    const { data: authData } = supabase.auth.getSession();
    if (!authData?.session) {
      const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
        if (event === 'SIGNED_IN') {
          Analytics.track('user_login_success', {
            user_id: session.user.id,
            auth_provider: session.user.app_metadata?.provider || 'email'
          });
          navigate('/dashboard');
        }
      });

      return () => {
        authListener.subscription.unsubscribe();
      };
    }
  }, [navigate]);

  return null;
}

// Add this new component
const PricingRedirect = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate('/#pricing', { replace: true });
    // Add a small delay to ensure the navigation happens before scrolling
    setTimeout(() => {
      const pricingSection = document.getElementById('pricing');
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }, [navigate]);

  return null;
};

// Add these new redirect components alongside PricingRedirect
const SectionRedirect = ({ sectionId }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate(`/#${sectionId}`, { replace: true });
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }, [navigate, sectionId]);

  return null;
};

function App() {
  return (
    <Router>
      <UserProvider>
        <AuthWrapper />
        <PageTracker />
        <Suspense fallback={
          <div className="flex items-center justify-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
          </div>
        }>
          <Routes>
            <Route path="/" element={<HomePageHeadshots />} />
            <Route path="/instagram" element={<HomePage />} />
            <Route path="/ai-headshots" element={<HomePageHeadshots />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/pricing" element={<PricingRedirect />} />
            <Route path="/how-it-works" element={<SectionRedirect sectionId="how-it-works" />} />
            <Route path="/why-choose-us" element={<SectionRedirect sectionId="why-choose-us" />} />
            <Route path="/reviews" element={<SectionRedirect sectionId="reviews" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </UserProvider>
    </Router>
  );
}

export default App;