// Analytics service to handle PostHog, Meta Pixel, and Google Analytics

const EVENT_MAPPING = {
  // Authentication Events
  user_signup_started: {
    meta: 'InitiateRegistration',
    google: 'begin_sign_up'
  },
  user_signup_completed: {
    meta: 'CompleteRegistration',
    google: 'sign_up'
  },
  user_login_success: {
    meta: 'Login',
    google: 'login'
  },
  user_login_failed: {
    meta: 'LoginFailed',
    google: 'login_failed'
  },
  user_logout: {
    meta: 'Logout',
    google: 'logout'
  },
  password_reset_requested: {
    meta: 'InitiatePasswordReset',
    google: 'password_reset'
  },

  // Upload Flow Events
  photo_upload_started: {
    meta: 'InitiateUpload',
    google: 'upload_start'
  },
  photo_upload_completed: {
    meta: 'CompleteUpload',
    google: 'upload_complete'
  },
  photo_upload_failed: {
    meta: 'UploadFailed',
    google: 'upload_failed'
  },
  photo_deleted: {
    meta: 'DeleteContent',
    google: 'content_delete'
  },
  upload_section_completed: {
    meta: 'CompleteStep',
    google: 'upload_step_complete'
  },
  style_selection_started: {
    meta: 'InitiateCustomization',
    google: 'begin_customization'
  },
  style_selection_completed: {
    meta: 'CompleteCustomization',
    google: 'complete_customization'
  },

  // Training Events
  training_started: {
    meta: 'InitiateTraining',
    google: 'begin_training'
  },
  training_completed: {
    meta: 'CompleteTraining',
    google: 'complete_training'
  },
  training_failed: {
    meta: 'TrainingFailed',
    google: 'training_failed'
  },

  // Generation Events
  generation_started: {
    meta: 'InitiateGeneration',
    google: 'begin_generation'
  },
  generation_completed: {
    meta: 'CompleteGeneration',
    google: 'complete_generation'
  },
  generation_failed: {
    meta: 'GenerationFailed',
    google: 'generation_failed'
  },
  prompt_submitted: {
    meta: 'SubmitPrompt',
    google: 'prompt_submission'
  },
  image_downloaded: {
    meta: 'Download',
    google: 'file_download'
  },
  image_shared: {
    meta: 'Share',
    google: 'share'
  },

  // Pricing/Payment Events
  pricing_page_viewed: {
    meta: 'ViewPricing',
    google: 'view_pricing'
  },
  plan_selected: {
    meta: 'SelectPlan',
    google: 'select_plan'
  },
  checkout_started: {
    meta: 'InitiateCheckout',
    google: 'begin_checkout',
    tiktok: 'InitiateCheckout'
  },
  checkout_completed: {
    meta: 'Purchase',
    google: 'purchase',
    tiktok: 'CompletePayment'
  },
  checkout_failed: {
    meta: 'PaymentFailed',
    google: 'payment_failed'
  },
  subscription_cancelled: {
    meta: 'CancelSubscription',
    google: 'subscription_cancel'
  },
  plan_purchased: {
    meta: 'Purchase',
    google: 'purchase'
  },

  // Navigation/UI Events
  page_viewed: {
    meta: 'PageView',
    google: 'page_view'
  },
  modal_opened: {
    meta: 'OpenModal',
    google: 'modal_open'
  },
  modal_closed: {
    meta: 'CloseModal',
    google: 'modal_close'
  },
  billing_drawer_opened: {
    meta: 'OpenBilling',
    google: 'billing_open'
  },
  billing_drawer_closed: {
    meta: 'CloseBilling',
    google: 'billing_close'
  },

  // Feature Usage Events
  credits_checked: {
    meta: 'CheckCredits',
    google: 'view_credits'
  },
  gallery_filtered: {
    meta: 'FilterContent',
    google: 'content_filter'
  },
  image_previewed: {
    meta: 'ViewContent',
    google: 'content_view'
  },
  help_documentation_accessed: {
    meta: 'ViewHelp',
    google: 'help_view'
  },

  // Error Events
  validation_error: {
    meta: 'ValidationError',
    google: 'validation_error'
  },
  api_error: {
    meta: 'APIError',
    google: 'api_error'
  },
  upload_error: {
    meta: 'UploadError',
    google: 'upload_error'
  },
  insufficient_credits_warning: {
    meta: 'InsufficientCredits',
    google: 'credits_warning'
  },

  // Legal/Policy Page Events
  policy_page_viewed: {
    meta: 'ViewContent',
    google: 'policy_view'
  },
  policy_link_clicked: {
    meta: 'ClickContent',
    google: 'policy_click'
  }
};

// Add standard properties that should be included with every event
const getStandardProperties = () => ({
  timestamp: new Date().toISOString(),
  url: window.location.href,
  path: window.location.pathname,
  referrer: document.referrer,
  device_type: getDeviceType(),
  browser_info: getBrowserInfo()
});

// Helper function to get device type
const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
};

// Helper function to get browser info
const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  const browserName = navigator.appName;
  const browserVersion = navigator.appVersion;
  return {
    userAgent: ua,
    browser: browserName,
    version: browserVersion
  };
};

// Add SHA-256 hashing function for email
const sha256 = async (text) => {
  const msgBuffer = new TextEncoder().encode(text);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};

class Analytics {
  static async track(eventName, customProperties = {}) {
    try {
      const properties = {
        ...getStandardProperties(),
        ...customProperties
      };

      // PostHog tracking
      if (window.posthog) {
        window.posthog.capture(eventName, properties);
      }

      // Meta Pixel tracking
      if (window.fbq) {
        const metaEventName = EVENT_MAPPING[eventName]?.meta || eventName;
        window.fbq('track', metaEventName, properties);
      }

      // Google Analytics tracking
      if (window.gtag) {
        const googleEventName = EVENT_MAPPING[eventName]?.google || eventName;
        window.gtag('event', googleEventName, {
          ...properties,
          event_category: getEventCategory(eventName),
          event_label: customProperties.label || eventName
        });
      }

      // TikTok tracking
      if (window.ttq) {
        const tiktokEventName = EVENT_MAPPING[eventName]?.tiktok;
        
        if (tiktokEventName) {
          // For checkout/payment events, include value and currency
          if (['InitiateCheckout', 'CompletePayment'].includes(tiktokEventName)) {
            window.ttq.track(tiktokEventName, {
              value: properties.value || properties.amount || 0,
              currency: 'USD'
            });
          } else {
            window.ttq.track(tiktokEventName);
          }
        }
      }
      
    } catch (error) {
      console.error('Analytics tracking error:', error);
    }
  }

  static async identify(userId, traits = {}) {
    try {
      // PostHog identify
      if (window.posthog) {
        window.posthog.identify(userId, traits);
      }

      // Google Analytics user properties
      if (window.gtag) {
        window.gtag('set', 'user_properties', traits);
      }

      // TikTok identify
      if (window.ttq && traits.email) {
        const hashedEmail = await sha256(traits.email);
        window.ttq.identify({
          email: hashedEmail
        });
      }
    } catch (error) {
      console.error('Analytics identify error:', error);
    }
  }

  static page(properties = {}) {
    try {
      // PostHog page view
      if (window.posthog) {
        window.posthog.capture('$pageview', properties);
      }

      // Meta Pixel page view
      if (window.fbq) {
        window.fbq('track', 'PageView', properties);
      }

      // Google Analytics page view
      if (window.gtag) {
        window.gtag('event', 'page_view', properties);
      }

      // TikTok page view
      if (window.ttq) {
        window.ttq.page();
      }
    } catch (error) {
      console.error('Analytics page tracking error:', error);
    }
  }
}

// Helper function to categorize events
const getEventCategory = (eventName) => {
  if (eventName.includes('user_')) return 'Authentication';
  if (eventName.includes('photo_')) return 'Upload';
  if (eventName.includes('training_')) return 'Training';
  if (eventName.includes('generation_')) return 'Generation';
  if (eventName.includes('checkout_')) return 'Payment';
  return 'Other';
};

export default Analytics; 