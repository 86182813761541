import React, { createContext, useState, useContext, useEffect } from 'react';
import { supabase } from './supabaseClient';
import Analytics from './services/analytics';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          setUser(user);
          // Track user identification
          Analytics.identify(user.id, {
            email: user.email,
            created_at: user.created_at
          });
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        Analytics.track('api_error', {
          error_type: 'auth_fetch_failed',
          error_message: error.message
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUser();

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      // Track authentication events
      switch (event) {
        case 'SIGNED_IN':
          Analytics.track('user_login_success', {
            user_id: session?.user?.id,
            auth_provider: session?.user?.app_metadata?.provider || 'email'
          });
          break;
        case 'SIGNED_OUT':
          Analytics.track('user_logout');
          break;
        case 'USER_UPDATED':
          Analytics.track('user_updated', {
            user_id: session?.user?.id
          });
          break;
        case 'PASSWORD_RECOVERY':
          Analytics.track('password_reset_requested');
          break;
      }

      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};